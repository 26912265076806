import React, { Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// import ProductDetail from '';
const CategoryPage = React.lazy(() => import("./pages/CategoryPage"));
const ProductDetail = React.lazy(() => import("./pages/ProductDetail"));

function AppRouter(props) {
  return (
    <Suspense fallback={null}>
      <Router>
        <Switch>
          <Route path="/">
            {props.isPDP ? (
              <ProductDetail></ProductDetail>
            ) : (
              <CategoryPage
                isCategoryLevel1={props.isCategoryLevel1}
                isVendor={props.isVendor}
                isBrand={props.isBrand}
              />
            )}
          </Route>
        </Switch>
      </Router>
    </Suspense>
  );
}

export default AppRouter;
