import React  from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import {  SwipeableDrawer } from '@material-ui/core';
import withApp from './../higherorder';
import { setDrawerData } from '../redux-store/actions/global.actions';
const CustomDrawer = () => {
    //const classes = useStyles();
    const { props, content, title } = useSelector(state => state.drawer, shallowEqual);
    const dispatch = useDispatch();
    const CloseDrawer = () => {
        dispatch(setDrawerData({ props: { open: false, anchor: 'right' } }))
    }
    return (
        <SwipeableDrawer onOpen={() => console.log()} onClose={CloseDrawer} {...props}>
            <div className="c-filter-drawer">
                <div className="mt-3">
                    <div className="c-filter-drawer__header">
                        <div className="c-filter-drawer__caption">
                            <h5 className="c-filter-drawer__title">{title}</h5>
                            <button onClick={CloseDrawer} className="c-button c-button--icon c-filter-drawer__close">
                                <i className="icon__fsd icon__fsd--close-gray"></i>
                            </button>
                        </div>
                    </div>
                    <div className="c-filter-drawer__body">
                        {content}
                    </div>
                </div>
            </div>
        </SwipeableDrawer>
    )
}

export default withApp(CustomDrawer);