export const SET_PRODUCT_DETAIL_DATA = "SET_PRODUCT_DETAIL_DATA";
export const SET_BREADCRUMBS = "SET_BREADCRUMBS";
export const SET_CUSTOMER_GROUP = "SET_CUSTOMER_GROUP";

export const IS_LOADING = "IS_LOADING";
export const ADD_TO_CART_QTY = "ADD_TO_CART_QTY";
export const PDP_PRICE = "PDP_PRICE";
export const PDP_IMAGE = "PDP_IMAGE";
export const PDP_TITLE = "PDP_TITLE";
export const PDP_CONFIGURABLE = "PDP_CONFIGURABLE";
export const PDP_SELECTED_OPTION = "PDP_SELECTED_OPTION";


export const SET_VIEW_MODE = "SET_VIEW_MODE";
export const SET_CATEGORY_DATA = "SET_CATEGORY_DATA";
export const SET_FILTER_DATA = "SET_FILTER_DATA";
export const SET_QUERY_DATA = "SET_QUERY_DATA";


export const SET_POPPER = "SET_POPPER";
export const SET_DRAWER = "SET_DRAWER";
export const SET_DIALOG = "SET_DIALOG";
