export default class Constants{
    imagePath = process.env.REACT_APP_BASE_PATH;
    
    get isMobile(){
        window.addEventListener('resize',()=>this.isMobile=window.matchMedia('(max-width: 600px)').matches)
        return window.matchMedia('(max-width: 600px)').matches
    }
    set isMobile(isMobile){
        return isMobile;
    }
}