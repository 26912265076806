import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./redux-store/store";
import UserContextProvider from "./context/usercontext";
import CartContextProvider from "./context/cartcontext";
import Router from "./router";
import CustomPopper from "./container/poper";
import Drawer from "./container/drawer";
import Dialog from "./container/popup";
let element =
  document.getElementById("pdp-v2") ||
  document.getElementById("plp-v2") ||
  document.getElementById("brand-product-listing-v2") ||
  document.getElementById("vendor-product-page-v2");
const categoryLevel1 = element.getAttribute("data-level1");
let isPDP = document.getElementById("pdp-v2") ? true : false;
let isBrand = isPDP
  ? false
  : document.getElementById("brand-product-listing-v2")
  ? true
  : false;
let isVendor = isPDP
  ? false
  : document.getElementById("vendor-product-page-v2")
  ? true
  : false;
// window.onload = function(){
//if(window.location.pathname==="/pdp-v2.html"){
const renderItem = () =>
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <UserContextProvider>
          <CartContextProvider>
            <Router
              isVendor={isVendor}
              isBrand={isBrand}
              isPDP={isPDP}
              isCategoryLevel1={categoryLevel1}
            />
            <CustomPopper />
            <Drawer />
            <Dialog />
            <div
              className="d-none"
              style={{
                position: "fixed",
                width: "100%",
                height: "100%",
                left: "0",
                zIndex: "99",
                top: "0",
                cursor: "not-allowed",
              }}
              id="cursor-wait"
            ></div>
          </CartContextProvider>
        </UserContextProvider>
      </Provider>
    </React.StrictMode>,
    element
  );
if (window.ReactDevelopment || window.FsdApp) {
  renderItem();
} else {
  document.addEventListener("bundle-load", () => {
    let refreshInterval = setInterval(() => {
      if (window.FsdApp) {
        renderItem();
        clearInterval(refreshInterval);
      }
    }, 100);
  });
}

//}
// }

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
