import { ClickAwayListener, Grow, makeStyles, Paper, Popper } from '@material-ui/core';
import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setPopperData } from '../redux-store/actions/global.actions';
const useStyles = makeStyles((theme) => ({
    root: {
        // display: 'flex',
        zIndex:'2'
    },
    paper: {
        minWidth: "250px",
        padding: " 15px 20px 20px 20px",
        width: "100%",
        background: " #fff",
        border: "1px solid #dddfe2",
        boxShadow: "0 10px 10px 0 rgba(0,0,0,0.3)",
        maxHeight: "60vh",
        overflowY: "auto",
    },
}));
const CustomPopper = () => {
    const classes = useStyles();
    const { props, action, content, title } = useSelector(state => state.popper, shallowEqual);
    const dispatch = useDispatch();
    const ClosePopper = () => {
         
         dispatch(setPopperData({props:{...props,open:false}}))
    }
    // useEffect(()=>{
    //     if(props.open){
            
    //     }
    // },[props])
    return (
        <ClickAwayListener onClickAway={ClosePopper}>
        <Popper className={classes.root} {...props} transition>
            {({ TransitionProps }) => (
                <Grow {...TransitionProps} timeout={100}>
                    
                        <Paper className={classes.paper}>
                            <div className="c-context-menu__caption">
                                <h3 className="c-context-menu__title">
                                    {title}
                                </h3>
                                <div onClick={ClosePopper} className="c-context-menu__close"></div>
                            </div>
                            {content}
                            {action}
                        </Paper>
                    
                </Grow>
            )}
        </Popper>
        </ClickAwayListener>
    )
}

export default CustomPopper;