import { Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setDialogData } from '../redux-store/actions/global.actions';
import withApp from './../higherorder';
const CustomPoppup = ({
    isCancelButton,
    isSubmitButton,
    submitButtonText,
    
}) => {
    const dialog = useSelector(state=>state.dialog,shallowEqual);
    if(dialog.props.hideSubmitButton) {
        isSubmitButton = false;
    }
    if(dialog.props.hideCancelButton) {
        isCancelButton = false;
    }
    const dispatch = useDispatch();
   const onClose = () =>{
        dispatch(setDialogData({props:{open:false}}));
   }
   const theme = useTheme();
    return (
        <Dialog
        {...dialog.props}
        fullWidth={'xs'}
        fullScreen={useMediaQuery(theme.breakpoints.down('sm'))}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
             <DialogTitle id="alert-dialog-title">{dialog.title}</DialogTitle>
             <DialogContent>
                    {dialog.content}
             </DialogContent>
             <DialogActions>
    {isCancelButton && <input type="button" value="Cancel" onClick={onClose} name="Cancel" className="c-button c-button--outline" /> }
    {isSubmitButton && <input type="submit" onClick={dialog.onSubmit} value={dialog.submitText || submitButtonText} name={"submit"} className="c-button c-button--primary" />}
             </DialogActions>
        </Dialog>
    )
}
CustomPoppup.defaultProps = {
    isCancelButton : true,
    isSubmitButton : true,
    submitButtonText : 'Submit'
}
export default withApp(CustomPoppup);