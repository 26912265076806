import React from "react";
class Method{
    imagePath = process.env.REACT_APP_BASE_PATH;
    formatPrice = (price) =>{
        // console.log(price);
        if(window.FsdApp) return window.FsdApp.i18nService.format(price, [
          "number",
          "currency",
        ])
        return "$"+Number(price).toFixed(2);
    }
    elementInViewport(el) {
        var top = el.offsetTop;
        var left = el.offsetLeft;
        var width = el.offsetWidth;
        var height = el.offsetHeight;
      
        while(el.offsetParent) {
          el = el.offsetParent;
          top += el.offsetTop;
          left += el.offsetLeft;
        }
        return (
          top < (window.pageYOffset + window.innerHeight) &&
          left < (window.pageXOffset + window.innerWidth) &&
          (top + height) > window.pageYOffset &&
          (left + width) > window.pageXOffset
        );
      }
      queryParams(params,history,location,deleteQuery){
          let currentUrlParams = new URLSearchParams(window.location.search);
          params.map(({key,value})=>{
            if(deleteQuery){
              currentUrlParams.delete(key);
            }else{
              currentUrlParams.set(key,value);
            }
            return true;
          })
          // return currentUrlParams;
          history.push(location.pathname + "?" +currentUrlParams.toString())
      }
      getparamsValue(key){
          let currentUrlParams = new URLSearchParams(window.location.search);
          return currentUrlParams.get(key)
      }
      getAllparamsValue(key){
        let currentUrlParams = new URLSearchParams(window.location.search);
        let filter ={};
        for(var pair of currentUrlParams.entries()) { 
          if(pair[0] === 'page'||  pair[0] === 'product_list_order'||  pair[0] === 'product_list_dir'){
            
          }else{
             filter[pair[0]] = pair[1];
          }
        
        } 
        return filter;
    }

    getCurrentPrice = (special_price , special_from_date , special_to_date , price ) => {

      let todayDate =  new Date().toISOString().split("T")[0];
          const specialPrice = Number(special_price);
          const originalPrice = Number(price);
  
          if (specialPrice == null){
            return originalPrice;  // show price if special price null
          }
          else if (specialPrice && specialPrice >= originalPrice){
            return originalPrice;
          } // show original price if special price is greater
          else if(specialPrice && special_from_date ==null && special_to_date == null){
            return specialPrice;  // always show special price
          }

          else if (specialPrice && 
            todayDate >=  special_from_date && 
            todayDate<= special_to_date){
            return specialPrice; // special price show according to date
          }
            else if (specialPrice && 
              todayDate >=  special_from_date && 
              special_to_date == null){
              return specialPrice; // special_to_date is null 
            }
            else if (specialPrice && 
              special_from_date == null && 
              todayDate<= special_to_date){
              return specialPrice; // special_from_date is null
            }
          else{
            return originalPrice;
          }
      }

      getOldPrice = (special_price , special_from_date , special_to_date , price) => {
        let todayDate =  new Date().toISOString().split("T")[0];
        const specialPrice = Number(special_price);
        const originalPrice = Number(price);
        if (specialPrice && specialPrice >= originalPrice){
          return null
        }
    
        else if(specialPrice && special_from_date == null && special_to_date == null){
          return  originalPrice;
         }
     
         else if (specialPrice && todayDate >= special_from_date && todayDate <= special_to_date){
           return originalPrice;
         }
         else if (specialPrice && 
          todayDate >=  special_from_date && 
          special_to_date == null){
            return originalPrice;
        }
        else if (specialPrice && 
          special_from_date == null && 
          todayDate<= special_to_date){
            return  originalPrice;
        }
     
       }

       getShipInDays = (ships_in_days) => {
        if (ships_in_days >= 21 && ships_in_days <= 30){
           return "3-4 week(s)"
        }
        else if (ships_in_days > 30){
          return "6-7 week(s)"
        }
    
        else {
          return `${ships_in_days} day(s)${ships_in_days === "1" ? "*" : ""}`
        }
    
      }

      showOutOfStockTag = (is_in_stock , qty) => {
        if(!is_in_stock || qty < 1){
         return(
          <div class="c-product-card_out-of-stock-flag">
          <span>OUT OF STOCK</span>
          </div>
         )
       
        }
      }
      

}
export default Method;