import React, { useEffect } from 'react';
import { useState } from 'react';

export const UserContext = React.createContext(null);

const UserContextProvider = (props) =>{
    const [user,setUser] = useState({
        isGuestUser:true,

    })
    useEffect(()=>{
        if(window.FsdApp){
            getUserDetails();
            window.FsdApp.accountService.addEventListener('user-change',()=>getUserDetails());
        }
        
    },[]);  // eslint-disable-line react-hooks/exhaustive-deps
    const getUserDetails = () =>{
        if(!window.FsdApp.accountService.isGuestUser){
            setUser(window.FsdApp.accountService.user);
        }
        
    }
    return (
        <UserContext.Provider value={user}>
            {props.children}
        </UserContext.Provider>
    )
}

export default UserContextProvider;