import { SET_POPPER,SET_DRAWER,SET_DIALOG } from "../constants";

export const popper = (state={props:{
  open:false,
 // anchorEl:null,
  placement:'bottom'
}},action)=>{
  console.log(action.type);
    switch (action.type) {
   
      case SET_POPPER:
          console.log(action.payload)
          return action.payload;
      default: return state;
    }
}

export const drawer = (state={props:{
  open:false,
  anchor:'right',
  // placement:'bottom'
}},action)=>{
  console.log(action.type);
    switch (action.type) {
   
      case SET_DRAWER:
          console.log(action.payload)
          return action.payload;
      default: return state;
    }
}
export const dialog = (state={props:{
  open:false,
  // placement:'bottom'
}},action)=>{
  console.log(action.type);
    switch (action.type) {
   
      case SET_DIALOG:
          return action.payload;
      default: return state;
    }
}