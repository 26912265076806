import {  SET_POPPER,SET_DRAWER, SET_DIALOG } from "../constants"

export const setPopperData = (view) =>{
    console.log(view);
    return {
        type : SET_POPPER,
        payload:view
    }
}
export const setDrawerData = (view) =>{
    console.log(view);
    return {
        type : SET_DRAWER,
        payload:view
    }
}
export const setDialogData = (view) =>{
    return {
        type : SET_DIALOG,
        payload:view
    }
}