import { IS_LOADING, SET_CATEGORY_DATA, SET_FILTER_DATA, SET_QUERY_DATA, SET_VIEW_MODE , SET_BREADCRUMBS } from "../constants";

export const isGridView = (state=true,action)=>{
    switch (action.type) {
      case SET_VIEW_MODE:
          return action.payload;
      default: return state;
    }
}

export const categoryData = (state=null,action)=>{
  switch (action.type) {
    case SET_CATEGORY_DATA:
        return action.payload;
    default: return state;
  }
}
export const filterData = (state=null,action)=>{
  switch (action.type) {
    case SET_FILTER_DATA:
        return action.payload;
    default: return state;
  }
}

export const queryData = (state=null,action)=>{
  switch (action.type) {
    case SET_QUERY_DATA:
        return action.payload;
    default: return state;
  }
}
export const isLoading = (state=false,action)=>{
  switch (action.type) {
    case IS_LOADING:
        return action.payload;
    default: return state;
  }
}

export const breadcrumbsData = (state=null,action)=>{
  switch (action.type) {
    case SET_BREADCRUMBS:
        return action.payload;
    default: return state;
  }
}